
import { defineComponent, ref } from 'vue';
import Radio from './Radio.vue';
import Progress from './Progress.vue';

import { Project, Standard, Item } from '../models'

export class Answer {
  [key: string]: string;
}

interface Delegation {
  [key: string]: boolean;
}

import dayjs from 'dayjs'

import { items_from_csv } from '../utils'

export default defineComponent({
  name: 'Report',
  components: {
    Radio,
    Progress,
  },
  props: {
    client: {},
    profile: {},
    project: {
      type: Project,
    },
    initial: null,
  },
  computed: {
    answer_type_list() {
      return [
        'Reject', 'Later', ''
      ];
    },
    user() {
      return this.$store.state.user;
    },
    can_generate_portals() {
      return (this.nodes || [ ]).find((n: any) => !n.portal_id) && !this.now_processing;
    },
    can_generate_spreadsheets() {
      return (this.nodes || [ ]).find((n: any) => !n.spreadsheet_id) && !this.now_processing;
    },
    assigned_items() {
      const is_my_node = (n: any) => (this.edit_assigned || n.admin == this.profile.email);
      return (this.standard as Standard).items.filter(item => {
        return 0 < (this.nodes_of_standard[this.standard.label] || [ ]).filter(is_my_node).length;
      });
    },
    assigned_item_ids() {
      return this.assigned_items.map((item: any) => {
        return this.standard.id + ' ' + item.id;
      });
    },
    todos() {
      const is_my_node = (n: any) => (this.edit_assigned || n.admin == this.profile.email);
      return (this.standard as Standard).items.filter(item => {
        const nodes = (this.nodes_of_standard[this.standard.label] || [ ]);
        for(let node of nodes.filter(is_my_node)) {
          const k = this.standard.id+' '+item.id+' ' + node.id;
          if(!this.answer || !this.answer[k]) {
            return true;
          }
        }
        return false;
      });
    },
    todo_ids() {
      return this.todos.map((item: any) => {
        return this.standard.id + ' ' + item.id;
      });
    },
    unresolved() {
      const nodes = this.selected_node ? [ this.selected_node ] : (this.nodes_of_standard[this.standard.label] || [ ]);
      return (this.standard as Standard).items.filter(item => {
        for(let node of nodes) {
          const k = this.standard.id+' '+item.id+' ' + node.id;
          if(!this.answer || !this.answer[k]) {
            return true;
          }
        }
        return false;
      });
    },
    unresolved_ids() {
      return this.unresolved.map((item: any) => {
        return this.standard.id + ' ' + item.id;
      });
    },
    project_admin() {
      if(this.nodes) {
        for(let n of this.nodes) {
          if(n.type == '-') {
            return n.admin;
          }
        }
      }
      return false;
    },
    nodes_of_standard(): Object {
      if(!this.nodes) {
        return { };
      }

      const r: any = { };
      for(let s of this.standards) {
        r[s.label] = [ ];
        for(let n of this.nodes) {
          if(n.type.split(/,/).includes(s.label)) {
            r[s.label].push(n);
          }
        }
      }
      return r;
    },
  },
  setup(props: any) {
    console.log('Reporter setup...');

    const selected_node = ref(null);
    const hide_resolved = ref(false);
    const only_assigned = ref(true);
    const edit_assigned = ref(false);
    const group_by_answer = ref(false);
    const delegation = ref<Delegation>({});
    const answer = ref<Answer>({});

    const client = props.client as Function;

    function update_answer(id: string, item: Item, node: any, value: string) {
      answer.value[id] = value;

      const update = {
        // @ts-ignore
        standard: this.standard.id,
        item_id: item.id,
        // @ts-ignore
        project: props.project?.id,
        node_id: node.id,
      };

      // @ts-ignore
      client.update_answer(props.project?.id, node.id, this.standard.label, this.standard.code+'-'+item.id, value, '', item.section, item.title)
      .then((_: any) => {
        // update_modified(update);
      });
      update_modified(update);
    }

    function update_description(id: string, item: Item, node_id: string) {
      const value = answer.value[id];
      const note = answer.value[id+' description'];
      answer.value[id+' description.saved'] = answer.value[id+' description'];

      const update = {
        // @ts-ignore
        standard: this.standard.id,
        item_id: item.id,
        // @ts-ignore
        project: props.project?.id,
        node_id: node_id,
      };

      // @ts-ignore
      client.update_answer(props.project?.id, node_id, this.standard.label, this.standard.code+'-'+item.id, value, note, item.section, item.title)
      .then((_: any) => {
        // update_modified(update)
      });
      update_modified(update)
    }

    let focus = ref('');
    function focus_item(item: Item) {
      this.focus = item.id;
    }
    function link_to_document(item: Item) {
      return item.document;
    }
    let nodes = ref(null);

    function on_load_answer(a: any) {
      answer.value[a.standard+' '+a.item_id+' '+a.node_id] = a.answer;
      answer.value[a.standard+' '+a.item_id+' '+a.node_id+' description'] = a.note || '';
      answer.value[a.standard+' '+a.item_id+' '+a.node_id+' created_on'] = dayjs(a.created_at).format('YYYY/MM/DD');
      answer.value[a.standard+' '+a.item_id+' '+a.node_id+' created_by'] = a.created_by;
    }

    function update_modified(a: any) {
      answer.value[a.standard+' '+a.item_id+' '+a.node_id+' created_on'] = dayjs(new Date()).format('YYYY/MM/DD');
      answer.value[a.standard+' '+a.item_id+' '+a.node_id+' created_by'] = props.profile.email;
    }

    const standard = ref(null);

    // ノード一覧取得
    // @ts-ignore
    props.client.get_project_nodes(props.project.name, '')
    .then((resp_nodes: any) => {
      console.log('found '+resp_nodes.length+' nodes.');
      nodes.value = resp_nodes;

      if(props.initial) {
        const standard_node = props.initial.split('/');
        const s = standards.value.filter(s => { return s.id == standard_node[0]; })[0];
        if(s) {
          select_standard(s, standard_node[1]);
        }
      }
    })

    const standards = ref([
        new Standard('system-devops', '内製システム', 'S01 内製システム管理基準', 'S01'),
        new Standard('device', 'デバイス', 'S02 デバイス管理基準', 'S02'),
        new Standard('stationary-device', '据え置き型デバイス', 'S16 据え置き型デバイス管理基準', 'S16'),
        new Standard('members', '人', 'S03 人的セキュリティ管理基準', 'S03'),
        new Standard('external-people', '外部関係者', 'S04 外部関係者管理基準', 'S04'),
        new Standard('saas', 'SaaS', 'S06 SaaS管理基準', 'S06'),
        new Standard('media', 'メディア', 'S09 メディア管理基準', 'S09'),
        new Standard('google-drive', '共有ドライブ', 'S15 共有ドライブ管理基準', 'S15'),
        new Standard('software', 'ソフトウェア', 'S10 ソフトウェア管理基準', 'S10'),
        new Standard('operation', 'オペレーション', 'S11 オペレーション管理基準', 'S11'),
        new Standard('personal-data-management', '個人情報', 'S12 個人情報管理基準', 'S12'),
        new Standard('access-control', 'アクセス制御', 'S14 アクセス制御基準', 'S14'),
    ]);

    const answered_item = ref(null);

    props.client.get_project_answered_item(props.project.id)
    .then((resp: { [node_id: string]: string[] }) => {
      const r: any = { };
      standards.value.forEach(s => {
        r[s.code] = [ ];
      });
      /* resp: node_id => item_ids[] */
      const node_ids: string[] = Object.keys(resp);
      node_ids.forEach((node_id: string) => {
        resp[node_id].forEach((item_id: string) => {
          const s_code = item_id.split('-')[0];
          if(!(s_code in r)) {
            console.warn('unknown standard code: '+s_code);
            return;
          }
          if(!r[s_code][node_id]) {
            r[s_code][node_id] = [ ];
          }
          r[s_code][node_id].push(item_id);
        });
      });
      answered_item.value = r;
    })

    function select_standard(selected: Standard | null, node_id: any) {
      if(!node_id) {
        if(selected) {
          location.hash = location.hash.replace(/^(#\/\d+)(\/.*)?$/, '$1') + '/'+selected!.id;
        } else {
          // @ts-ignore
          location.hash = location.hash.replace(/^(#\/\d+)\/.+$/, '$1');
        }
      }

      // @ts-ignore
      standard.value = selected;

      // @ts-ignore
      selected_node.value = nodes.value ? nodes.value.filter(n => { return n.id == node_id; })[0] : null;

      if(selected) {
        on_standard_selected();
      }
    }

    function open_check_result(selected_standard: Standard, node: any)
    {
      location.hash += '/'+selected_standard.id+'/'+node.id;

      // @ts-ignore
      standard.value = selected_standard;

      selected_node.value = node;

      on_standard_selected();
    }

    function on_standard_selected() {
      // @ts-ignore
      standard.value.items = undefined;

      // 回答ステータス一覧取得
      const load_answers = () => {
        // @ts-ignore
        return props.client.get_project_answers(props.project.id, standard.value.label)
        .then((resp_answers: any) => {
          resp_answers.sort((a: any, b: any) => {
            if (a.created_at < b.created_at) return +1;
            if (b.created_at < a.created_at) return -1;
            return 0;
          });
          const standard_id_by_label: any = {};
          standards.value.forEach(s => {
            standard_id_by_label[s.label] = s.id;
          });
          resp_answers.forEach((a: any) => {
            a.standard = standard_id_by_label[a.standard]; /* overwrite */
            a.item_id = a.item_id.replace(/^S\d+-/, ''); /* overwrite */

            if(answer.value[a.standard+' '+a.item_id+' '+a.node_id] === undefined) {
              on_load_answer(a);
            }
          });
        })
      };

      // チェック項目取得
      const load_items = () => {
        // @ts-ignore
        return fetch('/checklists/'+standard.value.id.toLowerCase()+'.csv')
        .then(r => r.text())
        .then(csv => {
          //@ts-ignore
          return items_from_csv(csv, standard.value.id.toLowerCase());
        });
      };

      Promise.all([ load_answers(), load_items() ]).then(result => {
        //@ts-ignore
        standard.value.items = result[1];
        //@ts-ignore
        console.log('found '+standard.value.items.length+ ' items');
      });
    }

    function get_spreadsheet_url(node: any) {
      return 'https://docs.google.com/spreadsheets/d/'+node.spreadsheet_id+'';
    }

    function get_portal_url(node: any) {
      const mode = node.admin == this.profile.email ? 'edit' : 'preview'
      return 'https://docs.google.com/document/d/'+node.portal_id+'/'+mode;
    }

    const now_processing = ref(0);
    
    function generate_portals() {
      if(!confirm('数十秒程度の時間がかかります. 開始しますか?')) {
        return;
      }
      now_processing.value++;
      props.client.generate_portals(props.project.name)
      .then((_: any) => {
        location.reload();
      })
    }

    function generate_spreadsheets() {
      if(!confirm('数十秒程度の時間がかかります. 開始しますか?')) {
        return;
      }
      now_processing.value++;
      props.client.generate_spreadsheets(props.project.name)
      .then((_: any) => {
        location.reload();
      })
    }

    const new_node = ref({ standards: [ ], name: '', admin: '' });

    function add_node() {
      const node = new_node.value;
      if(!node.name || node.standards.length == 0 && !node.admin) {
        alert('正しく入力してください');
        return;
      }
      props.client.add_node(props.project.name, node.name, node.standards, node.admin)
      .then(() => {
        node.name = '';
        node.admin = '';
        node.standards = [ ];
      })
    }

    function edit_note(node: any) {
      const text = prompt(node.name+'の備考', node.note);
      if(text !== null && text != node.note) {
        // update note
        props.client.update_node(node.id, 'NOTE', text)
        .then(() => {
          node.note = text;
        })
      }
    }

    function trim(s: string) {
      const max = 20;
      return s.length <= max+3 ? s : s.substr(0, 20)+"...";
    }

    const update_progress = (node: any, event: any) => {
      const progress = event.target.value;
      console.log([ node, progress ]);
      props.client.update_node(node.id, [ 'PROGRESS', 'PROGRESS_MODIFIED' ], [ progress, (new Date()).toISOString() ])
      .then(() => {
        node.progress = progress;
      })
    };

    return {
      now_processing,
      standards,
      standard,
      debug: ref(false),
      hide_resolved,
      only_assigned,
      edit_assigned,
      group_by_answer,
      nodes,
      selected_node,
      delegation,
      answer,
      update_answer,
      update_description,
      focus,
      focus_item,
      link_to_document,
      select_standard,
      open_check_result,
      get_spreadsheet_url,
      get_portal_url,
      generate_portals,
      generate_spreadsheets,
      new_node,
      add_node,
      answered_item,
      edit_note,
      trim,
      update_progress,
    };
  },
});
