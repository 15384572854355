
import { defineComponent, ref } from 'vue';
import { Answer } from './Reporter.vue';

export default defineComponent({
  name: 'Radio',
  props: {
    id: {
      type: String,
    },
    answer: {
      type: Answer,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  emits: [ 'change' ],
  setup(props, { emit }) {
    function update(ev: any) {
        this.value = ev.target.value;
        emit('change', ev.target.value);
    }
    //console.log(props.answer![props.id!]);
    return {
        value: ref(props.answer![props.id!] || ''),
        update,
    };
  },
  watch: {
    // FIXME: deep watchは(多分)遅いので、他の方法にしたい
    answer: {
      handler: function(new_answer) {
        if(new_answer[this.id]) {
          this.value = new_answer[this.id];
        }
      },
      deep: true,
    }
  }
});
