
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Progress',
  props: {
    node: {
      type: Object,
    },
  },
  emits: [ 'change' ],
  setup(props, { emit }) {
    const progress = ref(props.node ? props.node.progress : null);
    const update = (progress: string) => {
      emit('change', progress);
    }
    return {
      progress,
      update,
    };
  }
});
