<template>
  <select v-model="progress" @change="update">
    <option value="未着手">未着手</option>
    <option value="進行中">進行中</option>
    <option value="完了">完了</option>
  </select>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Progress',
  props: {
    node: {
      type: Object,
    },
  },
  emits: [ 'change' ],
  setup(props, { emit }) {
    const progress = ref(props.node ? props.node.progress : null);
    const update = (progress: string) => {
      emit('change', progress);
    }
    return {
      progress,
      update,
    };
  }
});
</script>
