export class Project {
  id: string;
  name: string;

  //delegation: Delegation;
  //answer: Answer;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
    
    //this.delegation = { };
    //this.answer = { };
  }
}

export class Item {
  id!: string;
  section!: string;
  title!: string;
  document!: string;
}

export class Standard {
  code!: string;
  id!: string;
  label!: string;
  name!: string;
  items: Array<Item>;

  constructor(id: string, label: string, name: string, code: string) {
    this.code = code;
    this.id = id;
    this.label = label;
    this.name = name;
    this.items = [ ];
  }
}