export function items_from_csv(csv: string, name: string) {
    if (!csv.match(/\n/)) {
      return [ ];
    }
    const lines = csv.replace(/^[^\n]+\n/, ''); // strip header
    const items = lines.substr(1, lines.length-2).split(/"\n"/)
    .filter(line => line.match(/,/))
    .map(line => {
        const columns = line.replace(/^"(.+)$/, '$1').split(/","/);
        return {
          id: (columns[3] || columns[2] || columns[1]).replace(/[^\d.]/g, '').replace(/\.$/, '')+'-'+columns[4],
          section: (columns[3] || columns[2] || columns[1]).replace(/^[\d\s.]+ /, ''),
          title: columns[5],
          document: 'https://site.internal.cureapp.co.jp/security/docs/standards/' + name + '#' + (columns[2] || columns[1]).replace(/ /, '-').replace(/[.]/g, ''),
        };
      });
    return items;
}
