<template>
  <span v-if="readonly">
    <span v-if="value == 'Accept'">遵守している</span>
    <span v-if="value == 'Later'">遵守予定</span>
    <span v-if="value == 'Reject'">遵守しない</span>
    <span v-if="value == 'NA'">該当なし</span>
    <span v-if="!value">未回答</span>
  </span>
  <span v-else>
    <label><input type="radio" @change="update" :checked="value == 'Accept'" value="Accept">遵守している</label>
    <label><input type="radio" @change="update" :checked="value == 'Later'"  value="Later">遵守予定</label>
    <label><input type="radio" @change="update" :checked="value == 'Reject'"  value="Reject">遵守しない</label>
    <label><input type="radio" @change="update" :checked="value == 'NA'"  value="NA">該当なし</label>
    <span v-if="value">
    &nbsp; &nbsp; <a href="#" @click.prevent="update({target: { value: '' }});">未選択に戻す</a>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Answer } from './Reporter.vue';

export default defineComponent({
  name: 'Radio',
  props: {
    id: {
      type: String,
    },
    answer: {
      type: Answer,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  emits: [ 'change' ],
  setup(props, { emit }) {
    function update(ev: any) {
        this.value = ev.target.value;
        emit('change', ev.target.value);
    }
    //console.log(props.answer![props.id!]);
    return {
        value: ref(props.answer![props.id!] || ''),
        update,
    };
  },
  watch: {
    // FIXME: deep watchは(多分)遅いので、他の方法にしたい
    answer: {
      handler: function(new_answer) {
        if(new_answer[this.id]) {
          this.value = new_answer[this.id];
        }
      },
      deep: true,
    }
  }
});
</script>
<style scoped>
label { cursor: pointer; }
</style>