import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import Rollbar from 'rollbar'

if (location.hostname != 'localhost') {
    new Rollbar({
        accessToken: '447488b519d14076ba141921a21706b0',
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: (location.hostname.match(/dev/) ? 'dev' : 'production'),
    });
}

createApp(App).use(store).mount('#app')
