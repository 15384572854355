<template>
  <div v-if="!auth_failed && !(profile.email) && now_loading == 0">
    Initialize...
  </div>
  <h1 v-else>ノード台帳</h1>
  <div id="banners">
    <div v-if="is_deprecated_url" class="important">
      このURLは廃止予定です。<a href="https://node-ledger.cureapp.jp">https://node-ledger.cureapp.jp</a> に移動しましょう。
    </div>
    <div>
      質問・不具合報告等は、お気軽に <a href="https://cureapp-team.slack.com/archives/C02412C723Z" target="_blank">#security-risk-management</a> まで！
    </div>
  </div>
  <div id="loading_indicator" v-if="0 < now_loading">
    Loading{{'.'.repeat(now_loading)}}
  </div>
  <div id="saving_indicator" v-if="0 < now_saving">
    Saving{{'.'.repeat(now_saving)}}
  </div>
  <div v-if="auth_failed">
    <button @click="process_auth()">login</button>
  </div>
  <div v-if="profile.email">
    <div class="system_status"><span id="auth_status">{{profile.email}}</span> [<span id="version">server.env={{server_env}} {{version}}</span>]</div>
    <template v-if="projects && !project">
      <div style="margin-bottom: 12px;">
        プロジェクト一覧
      </div>
      <ul>
        <li v-for="project in projects" :key="project.id">
          <a href="#" @click.prevent="select_project(project)">{{project.name}}</a>
        </li>
      </ul>
    </template>
    <template v-if="project">
      <Reporter ref="reporter" :client="client" :profile="profile" :project="project" :initial="initial_standard_node" @close="select_project()" />
    </template>
  </div>
  <div style="height: 40px;"></div>
</template>

<script lang="ts">
import { ref, computed } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Project } from './models';
import { create_api_client } from './client'

import Reporter from './components/Reporter.vue';

const ENDPOINT_PRODUCTION = {
  web: 'https://script.google.com/a/macros/cureapp.jp/s/AKfycbxFlUm3pm_yEZhSZHGm5cD0qnzjdn7C2GFP2RFLm1M8XpXuUvDRkIzDKGd4wvpjCsbx/exec',
  api: 'https://script.googleapis.com/v1/scripts/AKfycbxFlUm3pm_yEZhSZHGm5cD0qnzjdn7C2GFP2RFLm1M8XpXuUvDRkIzDKGd4wvpjCsbx:run'
};

const ENDPOINT_STAGING = {
  web: 'https://script.google.com/a/macros/cureapp.jp/s/AKfycbzkgakcVlQgwu51SKjXzceaMuA4_QWhcHOnGE1IUUZsndoINw-8ukTPuqhuQDNvVbX8/exec',
  api: 'https://script.googleapis.com/v1/scripts/AKfycbzkgakcVlQgwu51SKjXzceaMuA4_QWhcHOnGE1IUUZsndoINw-8ukTPuqhuQDNvVbX8:run'
};

const ENDPOINT_DEVELOP = {
  web: 'https://script.google.com/a/macros/cureapp.jp/s/AKfycbwhCeA7SQxX-_GGlQ5eSrX3frfsCgUNGikAYC08eug/dev',
  api: 'https://script.googleapis.com/v1/scripts/AKfycbwhCeA7SQxX-_GGlQ5eSrX3frfsCgUNGikAYC08eug:run'
};

const CLIENT_ID = '435769117855-068o15087pijapauli9uucupcv3b88ud.apps.googleusercontent.com';
const SCOPES = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/spreadsheets',
];

@Options({
  components: {
    Reporter
  },
})
export default class App extends Vue {
  data() {
    let server_env = ref('?');
    let version = ref('');
    let auth_failed = ref(false);
    let ref_client = ref(null);
    let profile = ref({ });
    let projects = ref(null);
    let project = ref(null);
    let initial_standard_node = ref(null);

    // @ts-ignore
    window.onbeforeunload = function() {
      // FIXME: 未保存だがdirtyの場合も警告したい
      if (0 < now_saving.value) {
        return '未保存のデータがありますが、本当に終了してもよろしいですか?';
      }
    };

    const manifest = fetch('/manifest.json')
    .then(res => res.json());

    manifest.then(m => {
      version.value = 'env='+m.env+(m.built_at ? ' built at '+m.built_at : '');
    })

    const get_gapi = function() {
      // @ts-ignore
      if(window['gapi'] && window['gapi'].auth) {
        // @ts-ignore
        return Promise.resolve(window['gapi']);
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            get_gapi().then(gapi => {
              resolve(gapi);
            });
          }, 100);
        });
      }
    };

    const indicator = (state: boolean, type: string) => {
      if(type == 'save') {
        if(state) {
          now_saving.value++;
        } else {
          now_saving.value--;
        }
      } else {
        if(state) {
          now_loading.value++;
        } else {
          now_loading.value--;
        }
      }
    };

    function auth(gapi: any, interactive: boolean, scopes: any) {
      return new Promise((resolve, reject) => {
        // immediate=falseにしないとdialogが開かない
        // が、ボタンを押したとき以外はdialogを開けない
        gapi.auth.authorize({ client_id: CLIENT_ID, scope: scopes, immediate: !interactive }, (authResult: any) => {
          if (authResult && !authResult.error) {
            resolve(authResult);
          } else {
            reject(authResult);
          }
        });
      });
    }

    const on_success = (gapi: any, manifest: any) => {
        fetch_profile(gapi).then((resp:any) => {
          console.log(resp);
          profile.value = {
            name: resp.name,
            email: resp.email,
          };
        })

        const endpoint = (env: string) => {
          if(env == 'production') {
            return ENDPOINT_PRODUCTION;
          }
          if(env == 'dev') {
            return ENDPOINT_STAGING;
          }
          if(env == 'local') {
            return ENDPOINT_DEVELOP;
          }

          // unknown env
          return ENDPOINT_DEVELOP;
        }

        const client = create_api_client(gapi, manifest, endpoint(manifest.env), indicator);
        // @ts-ignore
        ref_client.value = client;

        // @ts-ignore
        client.get_env()
        .then((resp: any) => {
          server_env.value = resp;
        })
        .catch((error: any) => {
          console.error(error);
        })

        // @ts-ignore
        client.get_session()
        .then((resp: any) => {
          console.log(resp);
        })
        .catch((error: any) => {
          console.error(error);
        })

        // @ts-ignore
        client.get_projects()
        .then((resp: any) => {
          projects.value = resp;
        })
        .catch((error: any) => {
          console.error(error);
        })
        .then(() => {
          // @ts-ignore
          const parsed = location.hash.split('/');
          if(parsed) {
            const project_id = parsed[1];
            const standard_id = parsed[2];
            const node_id = parsed[3];
            // @ts-ignore
            const p = projects.value.find(p => p.id == project_id);
            if(p) {
              // @ts-ignore
              initial_standard_node.value = [ standard_id, node_id ].join('/');
              select_project(p, true);
            }
          }
        })
    };

    get_gapi()
    .then((gapi: any) => {
      auth(gapi, false, SCOPES)
      .then((authResult) => {
        console.log(authResult);

        manifest.then((manifest: any) => {
          on_success(gapi, manifest);
        })
      })
      .catch((authResult) => {
        auth_failed.value = true;
        console.log(authResult);
        // このタイミングでログインボタンを出した方が良い???
        if(authResult.error == 'popup_blocked_by_browser') {
          // do nothing
        } else if(authResult.error == 'immediate_failed') {
          // do nothing
        } else {
          alert(authResult.error);
        }
      });
    })
    .catch(err => {
      console.warn(err);
    });

    function fetch_profile(gapi: any) {
      return new Promise((resolve, reject) => {
        gapi.client.request({ 'root': 'https://www.googleapis.com', 'path': '/oauth2/v3/userinfo', 'method': 'GET' }).execute((resp: any) => {
          resolve(resp);
        });
      });
    }

    function process_auth() {
      auth_failed.value = false;
      
      get_gapi()
      .then(gapi => {
        auth(gapi, true, SCOPES)
        .then((authResult) => {
          console.log(authResult);

          manifest.then((manifest: any) => {
            on_success(gapi, manifest);
          })
        })
      });
    }

    let now_saving = ref(0);
    let now_loading = ref(0);

    function select_project(p: any, skip_update_hash: boolean) {
      if(p) {
        if(!skip_update_hash) {
          location.hash = '#/'+p.id;
        }
        // @ts-ignore
        project.value = new Project(p.id, p.name);
      } else {
        if(!skip_update_hash) {
          location.hash = '#';
        }
        project.value = null;
      }
    }

    const is_deprecated_url = computed(() => {
      return server_env.value == 'prod' && location.hostname != 'node-ledger.cureapp.jp';
    });

    return {
      version: version,
      server_env: server_env,
      is_deprecated_url: is_deprecated_url,
      client: ref_client,
      auth_failed: auth_failed,
      profile: profile,
      process_auth: process_auth,
      projects: projects,
      project: project,
      initial_standard_node,
      select_project: select_project,
      now_saving: now_saving,
      now_loading: now_loading,
    };
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.system_status {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 70%;
}
#saving_indicator {
  position: fixed;
  top: 0;
  left: 0;
  background-color: red;
  color: white;
}
#loading_indicator {
  position: fixed;
  top: 0;
  left: 0;
  background-color: green;
  color: white;
}
#version {
  font-size: 70%;
}
#banners {
  position: fixed;
  bottom: 0px;
  right: 12px;
  color: #fff;
}
#banners div {
  padding: 6px;
  margin-bottom: 12px;
  border-radius: 4px;
  background-color: #393;
}
#banners div.important {
  background-color: rgb(199, 120, 31);
}
#banners div a {
  color: #fff;
}
</style>
