export function create_api_client(gapi: any, manifest: any, endpoints: any, indicator: any) {
    const dev_mode = manifest.env == 'local';
    const api_proxy = new Proxy({
        propertyMissingApi: (name: string) => {
            // @ts-ignore
            const url = endpoints.api;
            return function() {
                let args = Array.prototype.slice.call(arguments);
                console.log(name+JSON.stringify(args).replace(/^\[(.*)\]$/, '($1)') +'...');
                if(manifest.env != 'production') {
                    args = [ name ].concat(args);
                    name = 'run_on_dev';
                }
                return new Promise((resolve, reject) => {
                    indicator(true, 'load');
                    gapi.client.request({
                        'root': url.replace(/^(https:\/\/[^/]+)\/.+$/, '$1'),
                        'path': url.replace(/^https:\/\/[^/]+(\/.+)$/, '$1'),
                        'method': 'POST',
                        'body': {
                            'function': name,
                            // @ts-ignore
                            'parameters': args,
                            'devMode': dev_mode,
                        }
                    }).execute((resp: any) => {
                        indicator(false, 'load');
                        if(resp.done) {
                            if(resp.error) {
                                reject(resp.error);
                            } else {
                                resolve(resp.response.result);
                            }
                        } else {
                            console.warn(resp);
                        }
                    })
                });
            };
        },
        propertyMissingWeb: (name: string) => {
            // @ts-ignore
            const url = endpoints.web;
            return function() {
                let args = Array.prototype.slice.call(arguments);
                console.log(name+JSON.stringify(args).replace(/^\[(.*)\]$/, '($1)') +'...');
                if(manifest.env != 'production') {
                    args = [ name ].concat(args);
                    name = 'run_on_dev';
                }
                const request = {
                    'method': name,
                    'arguments': args,
                };
                indicator(true, 'save');
                return fetch(url, {
                    "method"     : "POST",
                    "mode"       : "no-cors",
                    "credentials": "include",
                    "headers": {
                        "Content-Type": "text/plain"
                    },
                    "body" : JSON.stringify(request)
                }).then((resp: any) => {
                    indicator(false, 'save');
                    return resp;
                });
            };
        }
    }, {
        get: (target, property: string) => {
            if (property.startsWith('get_')) {
                // get_で始まる場合は、Googleの認証情報を使ってGASのメソッドを呼ぶ
                return target.propertyMissingApi(property);
            } else {
                // それ以外は、匿名でPOST APIを呼ぶ(そしてCORS制約のためレスポンスは受け取れない)
                // FIXME: 独自の認証情報も載せるべき
                return target.propertyMissingWeb(property);
            }
        }
    });

    return api_proxy;
}
